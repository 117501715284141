import { createRouter, createWebHistory } from "vue-router";
import axiosConfig from "@/includes/axiosConfig.js";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },

  {
    path: "/main",
    name: "main",
    component: () => import("@/components/mainPage.vue"),
    meta: {
      breadCrumb: [{ name: "main", routeName: "main" }],
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/components/users/profile.vue"),
    meta: {
      breadCrumb: [
        { name: "main", routeName: "main" },
        { name: "profile", routeName: "profile" },
      ],
    },
  },
  {
    path: "/add-new-member/:id?",
    name: "add-new-member",
    component: () => import("@/components/users/addNewUser.vue"),
    meta: {
      breadCrumb: [
        { name: "main", routeName: "main" },
        { name: "add-new-member", routeName: "add-new-member" },
      ],
    },
  },
  {
    path: "/users-list",
    name: "users-list",
    component: () => import("@/components/users/usersList.vue"),
    meta: {
      breadCrumb: [
        { name: "main", routeName: "main" },
        { name: "users-list", routeName: "users-list" },
      ],
    },
  },
  {
    path: "/register-research/:id?",
    name: "register-research",
    component: () =>
      import("@/components/researchRegistration/submitResearch.vue"),
    meta: {
      breadCrumb: [
        { name: "main", routeName: "main" },
        { name: "register-research", routeName: "register-research" },
      ],
    },
  },
  {
    path: "/research/:Id",
    name: "research",
    component: () => import("@/components/researchPage.vue"),
    meta: {
      breadCrumb: [
        { name: "main", routeName: "main" },
        { name: "research", routeName: "research" },
      ],
    },
  },
  {
    path: "/researches-list",
    name: "researches-list",
    component: () => import("@/components/researchesList.vue"),
    meta: {
      breadCrumb: [
        { name: "main", routeName: "main" },
        { name: "researches-list", routeName: "researches-list" },
      ],
    },
  },
  {
    path: "/my-assigned-proposals",
    name: "my-assigned-proposals",
    component: () => import("@/components/myAssignedProposals.vue"),
    meta: {
      breadCrumb: [
        { name: "main", routeName: "main" },
        { name: "my-assigned-proposals", routeName: "my-assigned-proposals" },
      ],
    },
  },
  {
    path: "/verifyEmail",
    name: "verifyEmail",
    component: () => import("@/components/verifyEmail.vue"),
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: () => import("@/components/resetPassword.vue"),
  },
  {
    path: "/password-reset/:token",
    name: "password-reset",
    component: () => import("@/components/passwordReset.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: {
      breadCrumb: [
        { name: "Home", routeName: "Home" },
        { name: "About", routeName: "About" },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  {
    path: "/KPIs",
    name: "KPIs",
    meta: {
      breadCrumb: [
        { name: "main", routeName: "main" },
        { name: "KPIs", routeName: "KPIs" },
      ],
    },
    component: () => import("@/components/statistics/kpi.vue"),
  },
  {
    path: "/manuals",
    name: "manuals",
    meta: {
      breadCrumb: [
        { name: "main", routeName: "main" },
        { name: "manuals", routeName: "manuals" },
      ],
    },
    component: () => import("@/components/manuals&Faq/manuals.vue"),
  },
  {
    path: "/tutorials",
    name: "tutorials",
    meta: {
      breadCrumb: [
        { name: "main", routeName: "main" },
        { name: "tutorials", routeName: "tutorials" },
      ],
    },
    component: () => import("@/components/tutorials/tutorials.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    redirect: { name: "Home" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
});

router.afterEach(async () => {
  if (store.state.authenticated) {
    await axiosConfig
      .get("api/checkCurrentRole")
      .then()
      .catch((err) => {
        if (err.response.status == 403) {
          store.dispatch("chaneCurrentRole", err.response.data);
        }
      });
  }
});

export default router;
