<template>
  <v-offline @detected-condition="Online">
    <template v-if="online">
      <router-view :key="$route.path" />
    </template>

    <template v-if="!online">
      <noInternetConection />
    </template>
  </v-offline>
</template>

<script>
import { VOffline } from "v-offline";
import noInternetConection from "@/components/staticPages/noInternetConection.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    VOffline,
    noInternetConection,
  },
  name: "App",
  computed: {
    ...mapGetters({
      online: "online",
    }),
  },
  methods: {
    Online(e) {
      this.$store.dispatch("online", e);
    },
  },
  mounted() {
    document.getElementById("initializationLoading").remove();
  },
};
</script>
