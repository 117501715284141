import { createStore } from "vuex";
import axiosConfig from "@/includes/axiosConfig.js";
import { csrf } from "@/includes/token.js";

export default createStore({
  state: {
    authenticated: false,
    user: null,
    verified: false,
    faculties: [],
    categories: [],
    userCategories: [],
    studyTypes: [],
    researchStatuses: [],
    roles: [],
    rolesNames: [],
    online: window.navigator.onLine ? true : false,
    globalAlertClass: "",
    globalAlertMessage: "",
    showPopUpMessage: false,
  },
  mutations: {
    setAuthenticated(state, user) {
      state.authenticated = true;
      state.user = user;
    },
    setUnAuthenticated(state) {
      state.authenticated = false;
      state.user = null;
    },
    setVerified(state) {
      state.verified = true;
    },
    setUnVerified(state) {
      state.verified = false;
    },
    setFaculties(state, faculties) {
      state.faculties = faculties;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setUsersCategories(state, userCategories) {
      state.userCategories = userCategories;
    },
    setStudyTypes(state, studyTypes) {
      state.studyTypes = studyTypes;
    },
    setResearchStatuses(state, researchStatuses) {
      state.researchStatuses = researchStatuses;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setCurrentRole(state, data) {
      console.log(data);
      state.user != null ? (state.user.currentRole = data.currentRole) : "";
      state.user != null ? (state.user.userRoles = data.userRoles) : "";
    },
    onLine(state, e) {
      state.online = e;
    },
    setGlobalAlertMessage(state, message) {
      state.globalAlertMessage = message;
    },
    setGlobalAlertClass(state, Class) {
      state.globalAlertClass = Class;
    },
    setShowPopUpMessage(state, val) {
      state.showPopUpMessage = val;
    },
  },
  actions: {
    async checkIfAuthenticated({ commit, dispatch }) {
      await csrf();
      await axiosConfig
        .get("api/user")
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            commit("setAuthenticated", res.data);
            commit("setVerified");
            dispatch("fetchStudiesTypes");
            dispatch("fetchFaculties");
            dispatch("fetchUsersCategories");
            dispatch("fetchResearchCategories");
            dispatch("fetchRoles");
            dispatch("fetchResearchStatuses");
          }
        })
        .catch((err) => {
          if (err.response != null) {
            if (err.response.status == 409) {
              commit("setUnVerified");
              commit("setAuthenticated", null);
            } else {
              commit("setUnVerified");
              commit("setUnAuthenticated");
            }
          }
          console.log(err.response);
          // static data no need for authentication
          dispatch("fetchFaculties");
          dispatch("fetchUsersCategories");
          return;
        });
    },
    async fetchStudiesTypes({ commit }) {
      await axiosConfig
        .get("api/studiesTypes")
        .then((res) => {
          console.log(res);
          commit("setStudyTypes", res.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    async fetchFaculties({ commit }) {
      await axiosConfig
        .get("api/faculties")
        .then((res) => {
          commit("setFaculties", res.data);
        })
        .catch(() => {
          return;
        });
    },
    async fetchResearchCategories({ commit }) {
      await axiosConfig
        .get("api/researchCategories")
        .then((res) => {
          commit("setCategories", res.data);
        })
        .catch(() => {
          return;
        });
    },
    async fetchUsersCategories({ commit }) {
      await axiosConfig
        .get("api/userCategories")
        .then((res) => {
          commit("setUsersCategories", res.data);
        })
        .catch(() => {
          return;
        });
    },
    async fetchResearchStatuses({ commit }) {
      await axiosConfig
        .get("api/researchStatuses")
        .then((res) => {
          commit("setResearchStatuses", res.data);
        })
        .catch(() => {
          return;
        });
    },
    async fetchRoles({ commit }) {
      await axiosConfig
        .get("api/roles")
        .then((res) => {
          commit("setRoles", res.data);
        })
        .catch(() => {
          return;
        });
    },
    online({ commit }, e) {
      commit("onLine", e);
    },
    changeGlobalAlertMessage({ commit }, message) {
      commit("setGlobalAlertMessage", message);
    },
    changeGlobalAlertClass({ commit }, className) {
      commit("setGlobalAlertClass", className);
    },
    changeShowPopUpMessage({ commit }, val) {
      commit("setShowPopUpMessage", val);
    },
    chaneCurrentRole({ commit }, data) {
      commit("setCurrentRole", data);
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    faculties(state) {
      return state.faculties;
    },
    categories(state) {
      return state.categories;
    },
    studyTypes(state) {
      return state.studyTypes;
    },
    userCategories(state) {
      return state.userCategories;
    },
    researchStatuses(state) {
      return state.researchStatuses;
    },
    roles(state) {
      return state.roles;
    },
    rolesNames(state) {
      let rolesNames = [];
      state.roles.forEach((role) => {
        rolesNames.push(role.name);
      });
      return rolesNames;
    },
    online(state) {
      return state.online;
    },
    globalAlertMessage(state) {
      return state.globalAlertMessage;
    },
    globalAlertClass(state) {
      return state.globalAlertClass;
    },
    showPopUpMessage(state) {
      return state.showPopUpMessage;
    },
  },
  modules: {},
});
