import {
  Form as VeeForm,
  Field as VeeField,
  defineRule,
  ErrorMessage,
  configure,
} from "vee-validate";
import {
  required,
  max,
  min,
  alpha_spaces as alphaSpaces,
  alpha_dash as alphaDash,
  alpha_num as alphaNum,
  alpha,
  email,
  digits,
  numeric,
  max_value as maxVal,
  min_value as minVal,
  confirmed,
  not_one_of as excluded,
  regex,
} from "@vee-validate/rules";

export default {
  install(app) {
    app.component("VeeForm", VeeForm);
    app.component("VeeField", VeeField);
    app.component("ErrorMessage", ErrorMessage);

    defineRule("required", required);
    defineRule("tos", required);
    defineRule("max", max);
    defineRule("min", min);
    defineRule("alpha_spaces", alphaSpaces);
    defineRule("alpha_dash", alphaDash);
    defineRule("alpha_num", alphaNum);
    defineRule("alpha", alpha);
    defineRule("email", email);
    defineRule("digits", digits);
    defineRule("numeric", numeric);
    defineRule("max_value", minVal);
    defineRule("min_value", maxVal);
    defineRule("confirmed", confirmed);
    defineRule("excluded", excluded);
    defineRule("excluded_country", excluded);
    defineRule("regex", regex);

    configure({
      generateMessage(ctx) {
        const messages = {
          required: `the ${ctx.field} is required.`,
          max: `the ${ctx.field} is too long`,
          min: `the ${ctx.field} is too short`,
          alpha_spaces: `the ${ctx.field} may only contain alphabetical characters`,
          email: `the ${ctx.field} format is not correct`,
          digits: `the ${ctx.field} must be a digital number`,
          numeric: `the ${ctx.field} must be a numeric value`,
          max_value: `the ${ctx.field} is too high`,
          min_value: `the ${ctx.field} is too low`,
          excluded: "is not allowed to use this value",
          excluded_country: "we don't accept users from this location",
          confirmed: "the password don't match",
          tos: "you must accept the terms of service.",
        };
        const message = messages[ctx.rule.name]
          ? messages[ctx.rule.name]
          : `the ${ctx.field} field is not valid`;
        return message;
      },
      validateOnBlur: false,
      validateOnChange: false,
      validateOnInput: false,
      validateOnModelUpdate: false,
    });
  },
};
