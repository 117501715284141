import store from "./store";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VeeValidatePlugin from "./includes/validation";

/* bootstrap 5 */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

/* boxicons lib */
import "boxicons";

/* custom css */
import "@/assets/css/custom.css";
import "@/assets/css/main.css";
import "@/assets/css/researchDetails.css";
import "@/assets/css/liveSearch.css";

/* for generating pdf */
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";

/* datepicker (@vuepic/vue-datepicker) */
import Datepicker from "@vuepic/vue-datepicker";
import popUpMessage from "@/components/popUpMessage.vue";
import "@vuepic/vue-datepicker/dist/main.css";

store.dispatch("checkIfAuthenticated").then(() => {
  creatVueInctance();
});

function creatVueInctance() {
  const app = createApp(App);
  app.use(store);
  app.use(router);
  app.use(VeeValidatePlugin);
  app.use(Vue3SimpleHtml2pdf);
  app.component("Datepicker", Datepicker);
  app.component("popUpMessage", popUpMessage);
  app.mount("#app");
}
